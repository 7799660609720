/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  Fragment,
  useCallback,
  useEffect,
} from 'react';
import useParentWidth from '../../../hooks/useParentWidth';
import { useHistory } from 'react-router-dom';
import { StorageIcon, TickSuccessIcon } from '../icon';
import {
  PaginationNext,
  PaginationPrev,
} from '../../../assets/icons/nav-icons';
import { Modal, Button } from 'react-bootstrap';
import {
  TableLink,
  TableDateCell,
} from '../../../components/order/common/tableCells';
import Container from 'react-bootstrap/Container';
import Table, {
  TrashStatusFilter,
  TrashCustomerFilter,
  ModalColumnFilter,
} from '../../../components/order/common/table';
import { getOrders, inactiveOrder, orderDelete } from '../../../services/order';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@naadi/framework';
import { toastError } from '../../../helpers/packing/packingHelper';
import { FaExclamationCircle } from 'react-icons/fa';
import { cloneDeep, isArray, isEqual } from 'lodash';
import qs from 'qs';
import {
  formatFiltersObj,
  setPageFilterParams,
  showTrashModal,
} from '../../../store/order/ordersList/actions';
import { getOrderProgress } from '../../../services/order-operation-stat';
import moment from 'moment';
import Chip from '../../../components/common/chips';

import PropTypes from 'prop-types';
const width = { minWidth: '171px' };

const HeaderODelete = ({ selectedOrders }) => {
  const history = useHistory();
  const container = useRef();
  useParentWidth(container);
  const goBack = useCallback(() => {
    history.push('/app/orders/list');
  }, [history]);

  return (
    <Fragment>
      <div className='d-flex mt-3'>
        <div
          style={{ paddingLeft: '20px' }}
          className={'cursor-pointer'}
          onClick={goBack}
        >
          <svg
            width='32'
            height='28'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15 19L16.41 17.59L11.83 13L10.8294 12.0357L11.83 11L16.42 6.41L15 5L8 12L15 19Z'
              fill='black'
            />
          </svg>
        </div>
        <div className='page-title d-flex align-items-center'>
          Delete Orders ({selectedOrders.length} selected)
        </div>
      </div>
    </Fragment>
  );
};
export const StorageBar = ({ usedStorages, totalStorage = 5 }) => {
  const usedStorage = usedStorages / 1024;
  const usedPercentage = (usedStorage / totalStorage) * 100;
  const remaining = `${(totalStorage - usedStorage).toFixed(2)} GB`;
  const isExceeded = usedStorage > totalStorage;
  const progressBarColor = isExceeded ? 'bg-danger' : 'bg-primary';
  const iconColor = isExceeded ? ' #C00000' : '#027AFF';

  return (
    <div
      className='mt-2 position-relative'
      style={{
        width: '353px',
        left: '39px',
        height: '56px',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: '1px solid #F5F5F5',
        borderRadius: '8px',
        backgroundColor: '#F5F5F5',
      }}
    >
      <div
        className='d-flex align-items-center justify-content-between rounded'
        style={{ paddingTop: '2px' }}
      >
        <div className='d-flex align-items-center'>
          <StorageIcon iconColor={iconColor} />
          <span
            className='position-absolute'
            style={{ color: 'black', left: '60px', fontSize: '14px' }}
          >
            Storage
          </span>
        </div>
        <span
          style={{
            fontSize: '14px',
            color: isExceeded ? '#C00000' : '#027AFF',
          }}
        >
          {isExceeded ? `0 GB (${remaining})` : `${remaining}`} left
        </span>
      </div>
      <div
        className='progress position-relative'
        style={{
          height: '8px',
          borderRadius: '5px',
          maxWidth: '280px',
          left: '50px',
        }}
      >
        <div
          className={`progress-bar ${progressBarColor}`}
          role='progressbar'
          style={{ width: `${usedPercentage}%` }}
          aria-valuenow={usedPercentage}
          aria-valuemin='0'
          aria-valuemax='100'
        ></div>
      </div>
    </div>
  );
};

export const PaginationComponent = ({ totalCount, pageNo, onPageChange }) => {
  const pageSize = 50;
  const pageStart = pageNo * pageSize;
  const pageEnd = Math.min((pageNo + 1) * pageSize, totalCount);
  const isPrevDisabled = pageNo === 0;
  const isNextDisabled = pageEnd >= totalCount;
  const onPrevPage = () => {
    if (!isPrevDisabled) {
      const newPage = pageNo - 1;
      onPageChange(newPage);
    }
  };
  const onNextPage = () => {
    if (!isNextDisabled) {
      const newPage = pageNo + 1;
      onPageChange(newPage);
    }
  };
  return (
    <div className='d-flex justify-content-end mt-3'>
      <span style={{ fontSize: '12px' }}>Showing</span>
      <span
        className='text-primary ps-1'
        style={{ fontSize: '12px' }}
      >{`${pageStart} to ${pageEnd}`}</span>
      <span className='text-primary ps-1' style={{ fontSize: '12px' }}>
        of {totalCount}
      </span>
      <span
        style={{ padding: '0 10px', cursor: 'pointer' }}
        onClick={onPrevPage}
      >
        <PaginationPrev disabled={isPrevDisabled} />
      </span>
      <span
        style={{ padding: '0 16px', cursor: 'pointer' }}
        onClick={onNextPage}
      >
        <PaginationNext disabled={isNextDisabled} />
      </span>
    </div>
  );
};
export const TrashBanner = ({ show, orderCount, setShowModal, action }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  if (!show) return null;
  const message =
    action === 'restore'
      ? `${orderCount.length} Orders successfully restored`
      : action === 'deleted'
        ? `${orderCount.length} Orders Deleted successfully`
        : orderCount.length <= 1
          ? `${orderCount.length} Order moved to trash`
          : `${orderCount.length} Orders moved to trash`;
  const showViewTrash = action === undefined && orderCount.length >= 1;

  return (
    <div
      className='d-flex align-items-center justify-content-between'
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#2C2F33',
        color: '#FFFFFF',
        padding: '3px 10px',
        borderRadius: '20px',
        zIndex: 1050,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)',
        minWidth: '150px',
      }}
    >
      <TickSuccessIcon />
      <span style={{ fontSize: '10px', paddingLeft: '5px', paddingTop: '1px' }}>
        {message}
      </span>
      {showViewTrash && (
        <div
          onClick={() => {
            dispatch(showTrashModal());
            history.push('/app/orders/list');
          }}
          style={{
            color: '#027AFF',
            textDecoration: 'underline',
            marginLeft: '10px',
            fontWeight: 'bold',
            fontSize: '10px',
            cursor: 'pointer',
          }}
        >
          View Trash
        </div>
      )}
    </div>
  );
};

Chip.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export const RestoreDeletedOrderModal = ({
  showModal,
  setShowModal,
  onRestore,
  action,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState([{ column: 'created_on', asc: false }]);
  const sortByRef = useRef(sortBy);
  const [pageNo, setPageNo] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [restoreDelete, setRestoreDelete] = useState(false);
  const [progressData, setProgressData] = useState([]);
  const pageSize = 50;
  const filter_params =
    useSelector(state => state.ordersList.filterParams) || {};
  const handleSelectOrder = useCallback(
    order => {
      const isSelected = selectedOrders.includes(order);
      if (isSelected) {
        setSelectedOrders(prevSelectedOrders =>
          prevSelectedOrders.filter(selectedOrder => selectedOrder !== order),
        );
      } else {
        setSelectedOrders(prevSelectedOrders => [...prevSelectedOrders, order]);
      }
    },
    [selectedOrders],
  );

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(allOrders);
    }
    setSelectAll(prevSelectAll => !prevSelectAll);
  }, [selectAll, allOrders]);
  const handlePageChange = useCallback(newPageNo => {
    setPageNo(newPageNo);
  }, []);
  useEffect(() => {
    const fetchOrders = async () => {
      const filter = {
        term: filter_params.trash_modal_term || '',
        code: [],
        code_term: [],
        entity_id: filter_params.trash_modal_entity_id || [],
        ext_code_term: [filter_params.trash_modal_ext_code_term] || [],
        fetch_branch_detail: true,
        fetch_count: true,
        fetch_entities: true,
        notuuid: [],
        parent_order_id_term:
          [filter_params.trash_modal_parent_order_id_term] || [],
        ref_code: [],
        ref_code_term: [],
        secondary_customer_term:
          [filter_params.trash_modal_secondary_customer_term] || [],
        status: filter_params.trash_modal_status || [],
        delivery_date: '',
        name: [],
        customer_name_term: [],
      };

      const page_params = {
        page_no: pageNo,
        page_size: pageSize,
      };

      try {
        const allOrders = await getOrders(filter, page_params, null, false);
        const payload = allOrders.payload;
        const total = allOrders.total;
        setAllOrders(payload);
        if (pageNo === 0) {
          setTotalCount(total);
        } else {
        }
      } catch (error) {
        toastError.error('Failed to fetch orders:', error);
      }
    };

    fetchOrders();
  }, [filter_params, pageNo]);

  const restoreOrder = useCallback(
    async ordersToDelete => {
      try {
        dispatch(actions.busy.add('ORDER_DELETE'));
        onRestore(ordersToDelete);
        for (const order of ordersToDelete) {
          await inactiveOrder(order.uuid, true);
        }
        setSelectedOrders([]);
        action('restore');
      } catch (error) {
        toastError('order delete failed');
      } finally {
        dispatch(actions.busy.remove('ORDER_DELETE'));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [action, dispatch, onRestore],
  );
  const deleteOrder = useCallback(
    async ordersToDelete => {
      try {
        dispatch(actions.busy.add('ORDER_DELETE'));
        onRestore(ordersToDelete);
        for (const order of ordersToDelete) {
          await orderDelete(order.uuid);
        }
        setSelectedOrders([]);
        action('deleted');
      } catch (error) {
        toastError('order delete failed');
      } finally {
        dispatch(actions.busy.remove('ORDER_DELETE'));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [action, dispatch, onRestore],
  );
  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const from = moment().subtract(5, 'months').toDate();
        const response = await getOrderProgress(
          'ORDER',
          false,
          false,
          from,
          '',
          '',
          'ALL',
          300,
        );
        const payload = response.payload;
        setProgressData(payload);
      } catch (error) {
        toastError('Failed to fetch progress data:', error);
      }
    };

    fetchProgressData();
  }, []);
  const columns = [
    {
      Header: () => (
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            onChange={() => handleSelectAll()}
            checked={selectAll}
            style={{ marginRight: '5px', cursor: 'pointer' }}
          />
          <span className='ml-2'>Order</span>
        </div>
      ),
      accessor: 'ref_code',
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values, original } = row; // eslint-disable-line
        return (
          <div className='d-flex align-items-center'>
            <div className='d-flex'>
              <input
                type='checkbox'
                onChange={() => handleSelectOrder(row.original)}
                checked={selectedOrders.includes(row.original)}
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </div>
            <TableLink
              text={values.ref_code}
              url={`/app/orders/details/${original.uuid}`}
              is_bold
            />
          </div>
        ); // eslint-disable-line
      },
      Filter: ModalColumnFilter,
      width: 100,
      filter_key: 'trash_modal_term',
    },
    {
      Header: 'Parent Order',
      accessor: 'parent_order',
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        if (values.parent_order.uuid) {
          // eslint-disable-line
          return (
            <TableLink
              text={values.parent_order.ref_code}
              url={`/app/orders/details/${values.parent_order.uuid}`}
            />
          ); // eslint-disable-line
        }
        return <div></div>;
      },
      Filter: ModalColumnFilter,
      width: 100,
      filter_key: 'trash_modal_parent_order_id_term',
    },
    {
      Header: 'Customer Reference',
      accessor: 'ext_code',
      Filter: ModalColumnFilter,
      minWidth: '155px',
      filter_key: 'trash_modal_ext_code_term',
    },
    {
      Header: 'Customer name',
      accessor: 'customer_name',
      Filter: TrashCustomerFilter,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values, original } = row; // eslint-disable-line
        return (
          <TableLink
            text={values.customer_name}
            url={`/app/customers/${original.entity.uuid}`}
          />
        ); // eslint-disable-line
      },
      filter_key: 'trash_modal_entity_id',
    },
    {
      Header: 'Secondary Customer',
      accessor: 'secondary_customer',
      Cell: ({ row }) => {
        const { original } = row;
        if (!original.secondary_customer) {
          return <div></div>;
        }
        return <div>{original.secondary_customer}</div>;
      },
      filter_key: 'trash_modal_secondary_customer_term',
      Filter: ModalColumnFilter,
    },
    {
      Header: 'Branch',
      accessor: 'branch_id',
      filter_key: 'trash_modal_branch_id',
      Cell: ({ row }) => {
        const { original } = row;
        if (!original.branch_id || !original.branch) {
          return <div></div>;
        }
        return <div>{original.branch.name}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      filter_key: 'trash_modal_status',
      Filter: TrashStatusFilter,
    },
    {
      Header: 'Order Date',
      accessor: 'created_on',
      disableFilters: false,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        return <TableDateCell value={values.created_on} />;
      },
    },
    {
      Header: 'Planned Shipment Date',
      accessor: 'delivery_date',
      disableFilters: false,
      Cell: ({ row }) => {
        // eslint-disable-line
        const { values } = row; // eslint-disable-line
        return values.delivery_date ? (
          <TableDateCell value={values.delivery_date} />
        ) : (
          ''
        );
      },
    },
    {
      Header: 'Completion %',
      accessor: 'completion_percentage',
      disableFilters: true,
      Cell: ({ row }) => {
        const order = row.original;
        const matchingProgress = progressData.find(
          progress => progress.key === order.uuid,
        );
        return matchingProgress ? `${matchingProgress.completion}%` : '';
      },
    },
  ];

  const filterChipsParams = {
    trash_modal_term: {
      name: 'Term',
      key: 'trash_modal_term',
      type: 'string',
    },
    uuid: {
      name: 'uuid',
      key: 'uuid',
      type: 'array',
    },
    trash_modal_code_term: {
      name: 'Code',
      key: 'trash_modal_code_term',
      type: 'array',
    },
    trash_modal_ext_code_term: {
      name: 'Customer Reference',
      key: 'trash_modal_ext_code_term',
      type: 'array',
    },
    trash_modal_ref_code_term: {
      name: 'Reference Code Search',
      key: 'trash_modal_ref_code_term',
      type: 'array',
    },
    trash_modal_parent_order_id_term: {
      name: 'Parent Order ID',
      key: 'trash_modal_parent_order_id_term',
      type: 'array',
    },
    code: {
      name: 'Code',
      key: 'code',
      type: 'array',
    },
    trash_modal_ref_code: {
      name: 'Reference Code',
      key: 'trash_modal_ref_code',
      type: 'array',
    },
    trash_modal_entity_id: {
      name: 'Customer Name',
      key: 'trash_modal_entity_id',
      type: 'entity',
    },
    trash_modal_customer_name_term: {
      name: 'Customer Name',
      key: 'trash_modal_customer_name_term',
      type: 'array',
    },
    trash_modal_status: {
      name: 'Order Status',
      key: 'trash_modal_status',
      type: 'array',
    },
    trash_modal_secondary_customer_term: {
      name: 'Secondary Customer',
      key: 'trash_modal_secondary_customer_term',
      type: 'array',
    },
  };

  const filterParamChips = () => {
    const filter = [];

    Object.keys(filterChipsParams).map(param => {
      const filter_details = filterChipsParams[`${param}`];

      if (isArray(filter_params[`${param}`])) {
        filter_params[`${param}`].map(temp => {
          filter.push({
            name: filter_details?.name,
            value: temp,
            key: filter_details?.key,
          });
          return temp;
        });
      } else if (
        filter_details &&
        filter_params[`${param}`] &&
        filter_params[`${param}`].length
      ) {
        filter.push({
          name: filter_details.name,
          value: filter_params[`${param}`],
          key: filter_details.key,
        });
      }
      return filter_details;
    });
    return filter.map(c => {
      if (c.key === 'trash_modal_entity_id' && c.value) {
        return { ...c, value: allOrders[0]?.entity?.name };
      }
      return c;
    });
  };

  const removeFilter = chip_param => {
    const clone_filter_params = cloneDeep(filter_params);
    let filter_data_for_key = clone_filter_params[chip_param.key];
    if (isArray(filter_data_for_key)) {
      filter_data_for_key = filter_data_for_key.filter(function (item) {
        return item !== chip_param.value;
      });
      const new_filters = {
        ...clone_filter_params,
        [chip_param.key]: [],
      };
      dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
      const stringified_params = qs.stringify(formatFiltersObj(new_filters));
      history.push(`/app/orders/list?${stringified_params}`);
    } else if (filter_data_for_key) {
      const new_filters = {
        ...clone_filter_params,
        [chip_param.key]: '',
      };
      const stringified_params = qs.stringify(formatFiltersObj(new_filters));
      dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
      history.push(`/app/orders/list?${stringified_params}`);
    }
  };
  return (
    <div>
      <Modal show={showModal} size='xl' onHide={() => setShowModal(false)}>
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Modal.Title>
            Recently Deleted
            <p style={{ fontWeight: '200', fontSize: '12px' }}>
              Orders get automatically removed in 30 days of deletion
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '450px', padding: '0px' }}>
          <Container fluid className=' h-100'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
              }}
            >
              <div>
                {filterParamChips().map(chip => (
                  <Chip
                    key={`${chip.value}_${chip.key}`}
                    onClick={() => removeFilter(chip)}
                  >
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <div className='title-xs font-weight-bold'>
                        {chip.name}:
                      </div>
                      <div className='title-xs'>{chip.value}</div>
                    </div>
                  </Chip>
                ))}
              </div>
              <PaginationComponent
                totalCount={totalCount}
                pageNo={pageNo}
                onPageChange={handlePageChange}
              />
            </div>
            <div className='mt-2'>
              <Table
                columns={columns}
                data={allOrders}
                has_filters
                columnSort={sortBy}
                onSortChange={_sortBy => {
                  const updatedSortBy = _sortBy.map(val => {
                    return {
                      column: val.id,
                      asc: val.desc !== true,
                    };
                  });
                  if (!isEqual(sortByRef.current, updatedSortBy)) {
                    sortByRef.current = updatedSortBy;
                    setSortBy(sortByRef.current);
                  }
                }}
              />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
          <Button
            variant='outline-primary'
            style={width}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant='outline-danger'
            style={width}
            onClick={() => setShowConfirmDelete(true)}
          >
            {selectedOrders.length > 0 ? 'Delete Selected' : 'Delete All'}
          </Button>
          <Button
            variant='primary'
            style={width}
            onClick={() => setRestoreDelete(true)}
          >
            {selectedOrders.length > 0 ? 'Restore Selected' : 'Restore All'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        centered
      >
        <Modal.Header closeButton className='border-0 P-2'></Modal.Header>
        <Modal.Body
          className='text-center'
          style={{ paddingTop: '4px', paddingBottom: '50px' }}
        >
          <FaExclamationCircle size={48} className='text-danger mb-3' />
          <h5 className='fw-bold'>
            {selectedOrders.length > 0
              ? `Delete ${selectedOrders.length} orders permanently?`
              : `Delete all orders permanently?`}
          </h5>
          <p className='mb-0'>
            Permanently deleted orders cannot be restored in the future.
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0 P-2 d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            onClick={() => setShowConfirmDelete(false)}
            style={{ width: '46%' }}
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              setShowModal(false);
              deleteOrder(
                selectedOrders.length > 0 ? selectedOrders : allOrders,
              );
            }}
            style={{ width: '46%' }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={restoreDelete}
        onHide={() => setRestoreDelete(false)}
        centered
      >
        <Modal.Header closeButton className='border-0 P-2'></Modal.Header>
        <Modal.Body
          className='text-center'
          style={{ paddingTop: '4px', paddingBottom: '50px' }}
        >
          <FaExclamationCircle size={48} className='text-primary mb-3' />
          <h5 className='fw-bold'>Restore Recently Deleted</h5>
          <p className='mb-0'>
            {selectedOrders.length === 1
              ? 'Are you sure you want to restore the selected order?'
              : selectedOrders.length > 1
                ? 'Are you sure you want to restore the selected orders?'
                : 'Are you sure you want to restore all the orders?'}
          </p>
        </Modal.Body>
        <Modal.Footer className='border-0 P-2 d-flex justify-content-between'>
          <Button
            variant='outline-primary'
            onClick={() => setRestoreDelete(false)}
            style={{ width: '46%' }}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              setShowModal(false);
              restoreOrder(
                selectedOrders.length > 0 ? selectedOrders : allOrders,
              );
            }}
            style={{ width: '46%' }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HeaderODelete;
